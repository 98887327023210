
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import { Dictionary } from "vue-router/types/router";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";

import DateRangePicker from "@/components/form-components/DateRangePicker.vue";

export default Vue.extend({
  name: "Archive",

  components: { DateRangePicker },

  mixins: [tablePagination, tableFilters],

  data: () => ({
    parseArrayParams,
    filter: {} as Dictionary<string | (string | null)[]>,
    headers: [] as Array<any>,
    items: [] as Array<any>,
    selects: {
      managers: []
    } as any,
    loading: false as boolean
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 48
        }
      };
      const response = await API.archive().getData(params);
      const managersList = await API.archive().getManagersList();

      next(vm => {
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
        vm.selects.managers = managersList;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.archive().getData({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });
        const managersList = await API.archive().getManagersList();

        this.setServerResponse(response);
        this.selects.managers = managersList;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
      }

      if (newFilters.hasOwnProperty("manager_id")) {
        newFilters.manager_id = Number(newFilters.manager_id);
      }

      if (newFilters.hasOwnProperty("created_at")) {
        newFilters.created_at = newFilters.created_at.split(",");
      }

      if (newFilters.hasOwnProperty("deleted_at")) {
        newFilters.deleted_at = newFilters.deleted_at.split(",");
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    setServerResponse({ items, headers }: any) {
      this.headers = [
        ...headers,
        {
          text: "",
          value: "actions",
          align: "center",
          width: "30px",
          sortable: false
        }
      ];
      this.items = items.data;
      this.totalItems = items.total;
    },
    async restoreDocument(id: number) {
      try {
        await API.archive().restore(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async deleteClient(id: number) {
      try {
        await API.archive().deleteClient(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
